/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { Injectable, PipeTransform } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { DecimalPipe, SlicePipe } from '@angular/common';
import { debounceTime, delay, map, switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../directives/NgbdSortableHeader';
import { ProductDescriptionModel } from '../model/product_description.model';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': ['application/json'],
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};
const httpUploadOptions = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}

@Injectable({
  providedIn: 'root'
})
export class ProductDescriptionService {
  constructor(
    private httpClient: HttpClient
  ) { }

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _tableItem$ = new BehaviorSubject<any[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private _state: State = {
    page: 1,
    pageSize: 4,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  get tableItem$() { return this._tableItem$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: string) { this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }
  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }
  public add_product_description(data: ProductDescriptionModel): Observable<[boolean, any]> {
    let product_description_model: ProductDescriptionModel = {
      id: data.id,
      game_id: data.game_id,
      game_name: data.game_name == '' ? '-' : data.game_name,
      language: data.language ==''? 'English': data.language,
      description: data.description == '' ? '-' : data.description,
      ingame_description: data.ingame_description == '' ? '-' : data.ingame_description,
      game_market_description: data.game_market_description == '' ? '-' : data.game_market_description,
      nft_drops_description: data.nft_drops_description == '' ? '-' : data.nft_drops_description,
      created_at: data.created_at,
      updated_at: data.updated_at,
    };  
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient.post(environment.product_api + '/game_description', product_description_model, httpOptions).subscribe(
        (response: any) => {
          let data = response.data;
          observer.next([true, data]);
          observer.complete();
        },
        error => {
          observer.next([false, error.error.message]);
          observer.complete();
        });
    });
  }
  get_all_product_description(language: number): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {

      let product_list: ProductDescriptionModel[] = [];
      this.httpClient
        .get<any>(environment.product_api + '/game_description?' + '&game_id=' + language, httpOptions)
        .subscribe(
          (response) => {
            let result = response;
            // let result_data = results['data'];
            if (result != null) {
              var data = result['data'];
              if (data.length > 0) {
                for (let i = 0; i <= data.length - 1; i++) {
                  product_list.push({
                    id: data[i].id,
                    game_id: data[i].game_id,
                    game_name: data[i].game_name,
                    description: data[i].description,
                    language: data[i].language,
                    ingame_description: data[i].ingame_description,
                    game_market_description: data[i].game_market_description,
                    nft_drops_description: data[i].nft_drops_description,
                    created_at: data[i].created_at,
                    updated_at: data[i].updated_at
                  })
                }
              }
            }
            observer.next([true, product_list]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  public get_product_description_by_id(id: string): Observable<[boolean, any]> {

    return new Observable<[boolean, any]>((observer) => {
      let product_description_model: ProductDescriptionModel;
      this.httpClient
        .get<any>(
          environment.product_api + '/game_description/' + id,
          httpOptions
        )
        .subscribe(
          (response) => {
            let result = response;
            if (response != null) {
              product_description_model = {
                id: result['data'].id,
                game_id: result['data'].game_id,
                game_name: result['data'].game_name,
                description: result['data'].description,
                ingame_description: result['data'].ingame_description,
                game_market_description: result['data'].game_market_description,
                nft_drops_description: result['data'].nft_drops_description,
                language: result['data'].language!='' ? result['data'].language:'',
                created_at: result['data'].created_at,
                updated_at: result['data'].updated_at,
              };
            }

            observer.next([true, product_description_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  update_product_description(
    id: number,
    data: ProductDescriptionModel
  ): Observable<[boolean, ProductDescriptionModel]> {
    let product_model: ProductDescriptionModel = {
      id: data.id,
      game_name: data.game_name == '' ? '-' : data.game_name,
      game_id: data.game_id,
      description: data.description == '' ? '-' : data.description,
      ingame_description: data.ingame_description == '' ? '-' : data.ingame_description,
      game_market_description: data.game_market_description == '' ? '-' : data.game_market_description,
      nft_drops_description: data.nft_drops_description == '' ? '-' : data.nft_drops_description,
      language: data.language == '' ? 'English' : data.language,
      created_at: data.created_at,
      updated_at: data.updated_at,
    };
    return new Observable<[boolean, ProductDescriptionModel]>((observer) => {
      this.httpClient
        .put<ProductDescriptionModel>(
          environment.product_api + '/game_description/' + id,
          product_model,
          httpOptions
        )
        .subscribe(
          (response) => {
            let data = response;
            observer.next([true, data]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  // Delete Storage Type Material By ID
  delete_product_description(id: number): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient
        .delete<ProductDescriptionModel>(
          environment.product_api + '/game_description/' + id,
          httpOptions
        )
        .subscribe(
          (response) => {
            observer.next([true, response]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
}
