import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LabelModel } from './../model/label.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from 'src/app/app-settings';
import { CookiesService } from './cookies.service';
import { environment } from 'src/environments/environment';
import { PageModel } from "./../model/page.model"

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }),
};
@Injectable({
  providedIn: 'root'
})
export class LabelService {

  constructor(
    private appSettings: AppSettings,
    private httpClient: HttpClient
  ) { }

  public defaultAPIURLHost: string = environment.label_api;

  get_lables(page_model: PageModel): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {

      let label_list: LabelModel[] = [];

      this.httpClient
        .get<any>(this.defaultAPIURLHost + '/labels?page='+page_model.page+'&pageSize='+page_model.limit, httpOptions)
        .subscribe(
          (response) => {
            let result = response;
            let result_data = result['data'];
            if (result_data != null) {
              page_model = result_data
              // var data = results;
              // if (data.length > 0) {
              //   for (let i = 0; i <= data.length - 1; i++) {
              //     label_list.push({
              //       id: data[i].id,
              //       label: data[i].label,
              //       displayed_label: data[i].displayed_label,
              //     })
              //   }
              // }
            }
            observer.next([true, page_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  public add_label(label_model: LabelModel): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {

      let url = this.defaultAPIURLHost + '/labels';
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      };

      this.httpClient.post(url, label_model, httpOptions).subscribe(
        response => {
          let data = response;
          observer.next([true, data]);
          observer.complete();
        },
        error => {
          observer.next([false, error.error.message]);
          observer.complete();
        });
    });
  }
  public get_label_by_id(id: number): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {
      let label_model: LabelModel;
      this.httpClient
        .get<any>(
          this.defaultAPIURLHost + '/labels/' + id,
          httpOptions
        )
        .subscribe(
          (response) => {
            if (response != null) {
              label_model = {
                id: response['data'].id,
                label: response['data'].label,
                displayed_label: response['data'].displayed_label,

              };
            }

            observer.next([true, label_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  update_label(
    data: LabelModel
  ): Observable<[boolean, LabelModel]> {

    let label_model: LabelModel = {
      id: data.id,
      label: data.label == '' ? '-' : data.label,
      displayed_label: data.displayed_label == '' ? '-' : data.displayed_label,

    };
    console.log(label_model);
    return new Observable<[boolean, LabelModel]>((observer) => {
      this.httpClient
        .put<LabelModel>(
          this.defaultAPIURLHost + '/labels/',
          label_model,
          httpOptions
        )
        .subscribe(
          (response) => {
            let data = response;
            observer.next([true, data]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }

  // Delete Storage Type Material By ID
  delete_label(id: number): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient
        .delete<LabelModel>(
          this.defaultAPIURLHost + '/labels/' + id,
          httpOptions
        )
        .subscribe(
          (response) => {
            observer.next([true, response]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  label_all(language:string): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {

      let language_list: LabelModel[] = [];
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      };

      this.httpClient
        .get<any>(this.defaultAPIURLHost + '/labels/public?language='+language, {headers})
        .subscribe(
          (response) => {
            let results = response.labels;
            if (results != null) {
              var data = results;
              if (data.length > 0) {
                for (let i = 0; i <= data.length - 1; i++) {
                  language_list.push({
                    id: data[i].id,
                    label: data[i].label,
                    displayed_label: data[i].displayed_label,
                  })
                }
              }
            }
            observer.next([true, language_list]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
}
