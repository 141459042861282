<div class="media profile-media">
  <!-- <img class="b-r-10" src="assets/images/dashboard/profile.png" alt=""> -->
  <i class="b-r-10 icofont icofont-user" style="font-size: 20px;"></i>
  <div class="media-body"><span>{{user.name}}</span>
    <p class="mb-0 font-roboto">{{user.email}} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>


<ul class="profile-dropdown onhover-show-div">
  <!-- <li style="display: flex; justify-content: space-between;">
    <span>Balance:</span>
    <span>{{appSettings.wallet_info.wallet_balance_nms}} GNT</span>
  </li>
  <li style="display: flex; justify-content: space-between;">
    <span>Wallet:</span>
    <span style="    display: flex; gap: 0.5rem;">{{appSettings.wallet_info.wallet_keypair_display}} <a href="javascript:void(0);" (click)="copyInputMessage(appSettings.wallet_info.wallet_keypair)"><i class="fa fa-copy"></i></a></span>
  </li> -->
  <!-- <li><a href="https://dashboard.xgame.live/"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Dashboard</span></a></li> -->
  <li><a href="javascript:void(0);" (click)="logout()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span></a></li>
</ul>