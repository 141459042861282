import { Injectable } from '@angular/core';
import { TeamMembersModel } from "../model/team_members.model";
import { PageModel } from "../model/page.model";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': ['application/json'],
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};
const httpUploadOptions = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};

@Injectable({
  providedIn: 'root'
})
export class TeamMembersService {
  page_model: PageModel = new PageModel();
  constructor(
    private httpClient: HttpClient
  ) { }

  public add_team_members(data: TeamMembersModel): Observable<[boolean, any]> {
    let team_members_model: TeamMembersModel = {
      id: data.id,
      team_id: data.team_id,
      full_name: data.full_name == '' ? '-' : data.full_name,
      position: data.position == '' ? '-' : data.position,
      picture: data.picture == '' ? '-' : data.picture,
      linked_in: data.full_name == '' ? '-' : data.linked_in,
      github: data.position == '' ? '-' : data.github,
      facebook: data.picture == '' ? '-' : data.facebook,
      instagram: data.full_name == '' ? '-' : data.instagram,
      twitter: data.position == '' ? '-' : data.twitter,
      blog: data.picture == '' ? '-' : data.blog,
      artstation: data.artstation == '' ? '-' : data.artstation,
      unsave: data.unsave == '' ? 'DRAFT' : data.unsave,
      status: data.status,
    };

    return new Observable<[boolean, any]>((observer) => {
      this.httpClient.post(environment.team_api + '/team_members', team_members_model, httpOptions).subscribe(
        (response: any) => {
          let data = response.data;
          observer.next([true, data]);
          observer.complete();
        },
        error => {
          observer.next([false, error.error.message]);
          observer.complete();
        });
    });
  }
  get_all_team_members(page_model: PageModel): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {

      // let teams_list: TeamMembersModel[] = [];
      this.httpClient
        .get<any>(environment.team_api + '/team_members?page='+page_model.page+'&pageSize='+page_model.limit, httpOptions)
        .subscribe(
          (response) => {
            let result = response;
            let result_data = result['data'];
            if (result_data != null) {
              page_model = result_data
              // var data = result['data'];
              // if (data.length > 0) {
              //   for (let i = 0; i <= data.length - 1; i++) {
              //     teams_list.push({
              //       id: data[i].id,
              //       team_name: data[i].team_name,
              //       status: data[i].status,
              //     })
              //   }
              // }
            }
            observer.next([true, page_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  public get_team_member_by_id(id: number): Observable<[boolean, any]> {

    return new Observable<[boolean, any]>((observer) => {
      let team_members_model: TeamMembersModel;
      this.httpClient
        .get<any>(
          environment.team_api + '/team_members/' + id,
          httpOptions
        )
        .subscribe(
          (response) => {
            let result = response;
            if (response != null) {
              team_members_model = {
                id: result['data'].id,
                team_id: result['data'].team_id,
                full_name: result['data'].full_name,
                position: result['data'].position,
                picture: result['data'].picture,
                linked_in: result['data'].linked_in,
                github: result['data'].github,
                facebook: result['data'].facebook,
                instagram: result['data'].instagram,
                twitter: result['data'].twitter,
                blog: result['data'].blog,
                artstation: result['data'].artstation,
                unsave: result['data'].unsave,
                status: result['data'].status,
                
                // created_at: result['data'].created_at,
                // updated_at: result['data'].updated_at,
              };
            }

            observer.next([true, team_members_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }

  update_team_members(
    id: number,
    data: TeamMembersModel
  ): Observable<[boolean, TeamMembersModel]> {
    let team_members_model: TeamMembersModel = {
      id: Number (data.id),
      team_id: data.team_id,
      full_name: data.full_name == '' ? '-' : data.full_name,
      position: data.position == '' ? '-' : data.position,
      picture: data.picture == '' ? '-' : data.picture,
      linked_in: data.full_name == '' ? '-' : data.linked_in,
      github: data.position == '' ? '-' : data.github,
      facebook: data.picture == '' ? '-' : data.facebook,
      instagram: data.full_name == '' ? '-' : data.instagram,
      twitter: data.position == '' ? '-' : data.twitter,
      blog: data.picture == '' ? '-' : data.blog,
      artstation: data.artstation == '' ? '-' : data.artstation,
      unsave: data.unsave == '' ? 'DRAFT' : data.unsave,
      status: data.status,
      
    };
    return new Observable<[boolean, TeamMembersModel]>((observer) => {
      this.httpClient
        .put<TeamMembersModel>(
          environment.team_api + '/team_members/' + id,
          team_members_model,
          httpOptions
        )
        .subscribe(
          (response) => {
            let data = response;
            observer.next([true, data]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }

  // Delete Storage Type Material By ID
  delete_team_members(id: number): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient
        .delete<TeamMembersModel>(
          environment.team_api + '/team_members/' + id,
          httpOptions
        )
        .subscribe(
          (response) => {
            observer.next([true, response]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }

  public async uploadFile(file: any) {
    var formData: FormData = new FormData();
    formData.append('file', file, file.name)

    return await new Observable<[boolean, any]>((observer) => {
      this.httpClient.post<any>(environment.team_api + '/team_members/upload/image', formData, httpUploadOptions).subscribe(
        response => {
          let data = response;
          console.log(data);
          observer.next([true, data['data']]);
          observer.complete();
        },
        error => {
          observer.next([false, error.error.message]);
          observer.complete();
        });
    });
  }
}
