<div class="customizer-links" [ngClass]="{ open: customizer != '' }">
  <div class="nav flex-column nac-pills" id="c-pills-tab" role="tablist" aria-orientation="vertical">
    <a href="javascript:void(0)" class="nav-link" [ngClass]="{ 'active show': customizer == 'layout' }" (click)="Customizer('ChatBot')">
      <div class="settings"><i class="icofont icofont-robot-face"></i></div>
      <span>ChatBot</span>
    </a>
    <!-- <a href="javascript:void(0)" class="nav-link" [ngClass]="{ 'active show': customizer == 'option' }" id="c-pills-home-tab" (click)="Customizer('option')">
      <div class="settings"><i class="icon-settings"></i></div>
      <span>Cart</span>
    </a> -->
  </div>
</div>
<div class="customizer-contain" [ngClass]="{ open: customizer != '' }">
  <div class="tab-content" id="c-pills-tabContent">
    <div class="customizer-header">
      <i class="icofont-close icon-close" (click)="Customizer('')"></i>
      <h5>{{customizer}}</h5>
    </div>
    <div class="customizer-body custom-scrollbar">
      <div class="tab-pane fade" [ngClass]="{ 'active show': customizer == 'ChatBot' }" id="c-pills-layouts" role="tabpanel" style="display: flex; gap:1rem;flex-direction: column;" *ngFor="let chat of chatBotModel">
        <div class="sender" *ngIf="chat.receive_text!=null">
          <div class="">
            {{chat.receive_date_time}}
          </div>
          <div class="">
            {{chat.receive_text}}
          </div>
        </div>
        <div class="receiver" *ngIf="chat.send_text!=null">
          <div style="display: flex; justify-content: end;">
            {{chat.send_date_time}}
          </div>
          <div class="anim-typewriter">
            {{chat.send_text}}
          </div>
        </div>

      </div>
      <!-- <div class="tab-pane fade" [ngClass]="{ 'active show': customizer == 'option' }" id="c-pills-home" role="tabpanel" aria-labelledby="c-pills-home-tab">
        <h6>Shopping Cart</h6>
      </div> -->
    </div>
    <div class="customizer-footer">
      <div class="mb-3">
        <form #chatForm="ngForm" (ngSubmit)="chat_bot_send()">
          <div class="input-group">
            <input class="form-control" type="text" placeholder="Recipient's username" [(ngModel)]="chat_text" aria-label="Recipient's username" />
            <button class="btn btn-primary"><i class="fa fa-send-o" ></i></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
