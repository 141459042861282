import { Injectable } from '@angular/core';
import { LabelModel } from 'src/app/models/administration/label.model';
import { AppSettings } from 'src/app/app-settings';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageModel, TrainBotModel } from './../model/message.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }),
};
@Injectable({
  providedIn: 'root'
})
export class ChatBotService {

  constructor(
    private appSettings: AppSettings,
    private httpClient: HttpClient
  ) { }

  public defaultAPIURLXGAMENLPHost: string = this.appSettings.APIURLHostXGAMENLP;
  public defaultAPIURLCASINONLPHost: string = this.appSettings.APIURLHostCASINONLP;

  train_bot(message:TrainBotModel): Observable<any> {
    return new Observable<any>((observer) => {

      let url = this.defaultAPIURLXGAMENLPHost + '/api/chatbot/train_model';
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      };

      this.httpClient.post(url, message, httpOptions).subscribe(
        response => {
          let data = response;
          observer.next(data);
          observer.complete();
        },
        error => {
          observer.next([false, error.error.message]);
          observer.complete();
        });
    });
  }

  get_xgame_nlp(keywords: string, skip: number, take: number): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {
      let label_list: MessageModel[] = [];

      this.httpClient
        .get<any>(this.defaultAPIURLXGAMENLPHost + '/api/messages?=skip='+skip+'&limit='+take, httpOptions)
        .subscribe(
          (response) => {
            let results = response;
           
            if (results != null) {
              var data = results;
              if (data.length > 0) {
                for (let i = 0; i <= data.length - 1; i++) {
                  label_list.push({
                    id: data[i].id,
                    is_trained: data[i].is_trained ,
                    tag: data[i].tag,
                    input: data[i].input,
                    response: data[i].response,
                  })
                }
              }
            }
            observer.next([true, label_list]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  get_casino_nlp(keywords: string, skip: number, take: number): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {
      let label_list: MessageModel[] = [];

      this.httpClient
        .get<any>(this.defaultAPIURLCASINONLPHost + '/api/messages?=skip='+skip+'&limit='+take, httpOptions)
        .subscribe(
          (response) => {
            let results = response;
           
            if (results != null) {
              var data = results;
              if (data.length > 0) {
                for (let i = 0; i <= data.length - 1; i++) {
                  label_list.push({
                    id: data[i].id,
                    is_trained: data[i].is_trained ,
                    tag: data[i].tag,
                    input: data[i].input,
                    response: data[i].response,
                  })
                }
              }
            }
            observer.next([true, label_list]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  public add_messages(message_model: MessageModel): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {
      let url = this.defaultAPIURLXGAMENLPHost + '/api/messages';
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      };

      this.httpClient.post(url, message_model, httpOptions).subscribe(
        response => {
          let data = response;
          observer.next([true, data]);
          observer.complete();
        },
        error => {
          observer.next([false, error.error.message]);
          observer.complete();
        });
    });
  }
  public get_messages_by_id(id: number): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {
      let message_model: MessageModel;

      this.httpClient
        .get<any>(
          this.defaultAPIURLXGAMENLPHost + '/api/messages/' + id,
          httpOptions
        )
        .subscribe(
          (response) => {
            if (response != null) {
              message_model = {
                id: response.id,
                is_trained: response.is_trained,
                tag: response.tag,
                input: response.input,
                response: response.response,

              };
            }

            observer.next([true, message_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  update_messages(data: MessageModel): Observable<[boolean, MessageModel]> {

    let nft_model: MessageModel = {
      id: data.id,
      is_trained: data.is_trained,
      tag: data.tag == '' ? '-' : data.tag,
      input: data.input == '' ? '-' : data.input,
      response: data.response == '' ? '-' : data.response,

    };

    return new Observable<[boolean, MessageModel]>((observer) => {
      this.httpClient.put<MessageModel>(
          this.defaultAPIURLXGAMENLPHost + '/api/messages/'+data.id+'/',
          nft_model,
          httpOptions
        )
        .subscribe(
          (response) => {
            let data = response;
            observer.next([true, data]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  // Delete Storage Type Material By ID
  delete_messages(id: number): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient
        .delete<LabelModel>(
          this.defaultAPIURLXGAMENLPHost + '/api/messages/' + id +'/',
          httpOptions
        )
        .subscribe(
          (response) => {
            observer.next([true, response]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  public send_chatbot(text:string): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient.post(this.appSettings.chatBotURLHOST , JSON.stringify({input_text:text}),httpOptions).subscribe(
        (response: any) => {
          let data = response.message;
          observer.next([true, data]);
          observer.complete();
        },
        error => {
          observer.next([false, error.error.message]);
          observer.complete();
        });
    });
  }

}