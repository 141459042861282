import { Injectable } from '@angular/core';
import { TeamMemberOverviewModel } from "../model/team_member_overview.model";
import { PageModel } from "../model/page.model";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': ['application/json'],
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};
const httpUploadOptions = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};

@Injectable({
  providedIn: 'root'
})
export class TeamMemberOverviewService {
  page_model: PageModel = new PageModel();
  constructor(
    private httpClient: HttpClient
  ) { }

  public add_team_member_overview (data: TeamMemberOverviewModel): Observable<[boolean, any]> {
    let team_member_overview_list: TeamMemberOverviewModel = {
      id: data.id,
      team_member_id: data.team_member_id,
      overview_description: data.overview_description == '' ? '-' : data.overview_description,
      language: data.language == '' ? '-' : data.language,
    };

    return new Observable<[boolean, any]>((observer) => {
      this.httpClient.post(environment.team_api + '/team_member_overview', team_member_overview_list, httpOptions).subscribe(
        (response: any) => {
          let data = response.data;
          observer.next([true, data]);
          observer.complete();
          console.log(data)

        },
        error => {
          observer.next([false, error.error.message]);
          observer.complete();
        });
    });
  }

  // get_team_member_overview_list(page_model: PageModel,team_member_id: number): Observable<[boolean, any]> {
  //   return new Observable<[boolean, any]>((observer) => {

  //     let team_member_overview_list: TeamMemberOverviewModel[] = [];

  //     this.httpClient
  //       .get<any>(environment.team_api + '/team_member_overview?page='+page_model.page+'&pageSize='+page_model.limit+'&team_member_id='+team_member_id, httpOptions)
  //       .subscribe(
  //         (response) => {
  //           let result = response;
  //           let result_data = result['data'];
  //           if (result_data != null) {
  //             page_model = result_data
  //             // var data = results;
  //             // if (data.length > 0) {
  //             //   for (let i = 0; i <= data.length - 1; i++) {
  //             //     label_list.push({
  //             //       id: data[i].id,
  //             //       label: data[i].label,
  //             //       displayed_label: data[i].displayed_label,
  //             //     })
  //             //   }
  //             // }
  //           }
  //           observer.next([true, page_model]);
  //           observer.complete();
  //         },
  //         (error) => {
  //           observer.next([false, error.status]);
  //           observer.complete();
  //         }
  //       );
  //   });
  // }

  get_team_member_overview(page_model: PageModel, team_member_id: Number): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {

      // let teams_list: TeamMembersModel[] = [];
      this.httpClient
        .get<any>(environment.team_api + '/team_member_overview?page='+page_model.page+'&pageSize='+page_model.limit+'&team_member_id='+team_member_id, httpOptions)
        .subscribe(
          (response) => {
            let result = response;
            let result_data = result['data'];
            if (result_data != null) {
              page_model = result_data
              // var data = result['data'];
              // if (data.length > 0) {
              //   for (let i = 0; i <= data.length - 1; i++) {
              //     teams_list.push({
              //       id: data[i].id,
              //       team_name: data[i].team_name,
              //       status: data[i].status,
              //     })
              //   }
              // }
            }
            observer.next([true, page_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  public get_team_member_overview_by_id(id: number): Observable<[boolean, any]> {

    return new Observable<[boolean, any]>((observer) => {
      let team_member_overview_model: TeamMemberOverviewModel;
      this.httpClient
        .get<any>(
          environment.team_api + '/team_member_overview/' + id,
          httpOptions
        )
        .subscribe(
          (response) => {
            let result = response;
            if (response != null) {
              team_member_overview_model = {
                id: result['data'].id,
                team_member_id: result['data'].team_member_id,
                overview_description: result['data'].overview_description,
                language: result['data'].language,
                
                // created_at: result['data'].created_at,
                // updated_at: result['data'].updated_at,
              };
            }

            observer.next([true, team_member_overview_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }

  update_team_member_overview(
    id: number,
    data: TeamMemberOverviewModel
  ): Observable<[boolean, TeamMemberOverviewModel]> {
    let team_member_overview_model: TeamMemberOverviewModel = {
      id: data.id,
      team_member_id: data.team_member_id,
      overview_description: data.overview_description == '' ? '-' : data.overview_description,
      language: data.language == '' ? '-' : data.language,
      
    };
    return new Observable<[boolean, TeamMemberOverviewModel]>((observer) => {
      this.httpClient
        .put<TeamMemberOverviewModel>(
          environment.team_api + '/team_member_overview/' + id,
          team_member_overview_model,
          httpOptions
        )
        .subscribe(
          (response) => {
            let data = response;
            observer.next([true, data]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }

  // Delete Storage Type Material By ID
  delete_team_member_overview(id: number): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient
        .delete<TeamMemberOverviewModel>(
          environment.team_api + '/team_member_overview/' + id,
          httpOptions
        )
        .subscribe(
          (response) => {
            observer.next([true, response]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
}
