import { Injectable } from '@angular/core';
import { BlogTranslationModel } from "./../model/blog_translation.model"
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PageModel } from "./../model/page.model"

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': ['application/json'],
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};
const httpUploadOptions = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};

@Injectable({
  providedIn: 'root'
})
export class BlogTranslationService {

  constructor(
    private httpClient: HttpClient
  ) { }

  public add_blog_translation(data: BlogTranslationModel): Observable<[boolean, any]> {
    let blog_translation_model: BlogTranslationModel = {
      id: data.id,
      blog_id: data.blog_id,
      title: data.title == '' ? '-' : data.title,
      body: data.body == '' ? '-' : data.body,
      language: data.language == '' ? 'English' : data.language,
      created_at: data.created_at,
      updated_at: data.updated_at,
    };
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient.post(environment.blog_api + '/blog_translation', blog_translation_model, httpOptions).subscribe(
        (response: any) => {
          let data = response.data;
          observer.next([true, data]);
          observer.complete();
        },
        error => {
          observer.next([false, error.error.message]);
          observer.complete();
        });
    });
  }
  get_all_blog_translation(page_model: PageModel,blog_id:number): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {

      let blog_list: BlogTranslationModel[] = [];
      this.httpClient
        .get<any>(environment.blog_api + '/blog_translation?page='+page_model.page+'&pageSize='+page_model.limit+'&blog_id=' + blog_id, httpOptions)
        .subscribe(
          (response) => {
            let result = response;
            let result_data = result['data'];
            if (result_data != null) {
              page_model = result_data
            }
            observer.next([true, page_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  public get_blog_translation_by_id(id: string): Observable<[boolean, any]> {

    return new Observable<[boolean, any]>((observer) => {
      let blog_translation_model: BlogTranslationModel;
      this.httpClient
        .get<any>(
          environment.blog_api + '/blog_translation/' + id,
          httpOptions
        )
        .subscribe(
          (response) => {
            let result = response;
            if (response != null) {
              blog_translation_model = {
                id: result['data'].id,
                blog_id: result['data'].blog_id,
                title: result['data'].title,
                body: result['data'].body,
                language:result['data'].language,
                created_at: result['data'].created_at,
                updated_at: result['data'].updated_at,
              };
            }
            console.log(result['data'].language);
            observer.next([true, blog_translation_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  update_blog_translation(
    id: number,
    data: BlogTranslationModel
  ): Observable<[boolean, BlogTranslationModel]> {
    let blog_model: BlogTranslationModel = {
      id: data.id,
      blog_id: data.blog_id,
      title: data.title == '' ? '-' : data.title,
      body: data.body == '' ? '-' : data.body,
      language: data.language == '' ? 'English' : data.language,
      created_at: data.created_at,
      updated_at: data.updated_at,
    };
    return new Observable<[boolean, BlogTranslationModel]>((observer) => {
      this.httpClient
        .put<BlogTranslationModel>(
          environment.blog_api + '/blog_translation/' + id,
          blog_model,
          httpOptions
        )
        .subscribe(
          (response) => {
            let data = response;
            observer.next([true, data]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  // Delete Storage Type Material By ID
  delete_blog_translation(id: number): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient
        .delete<BlogTranslationModel>(
          environment.blog_api + '/blog_translation/' + id,
          httpOptions
        )
        .subscribe(
          (response) => {
            observer.next([true, response]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
}
