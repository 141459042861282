import { Injectable, Input } from '@angular/core';
import { BlogModel } from "./../model/blog.model"
import { AppSettings } from 'src/app/app-settings';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductDropdownModel, ProductModel } from '../model/product.model';
import { environment } from 'src/environments/environment';
import { PageModel } from '../model/page.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': ['application/json'],
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};
const httpUploadOptions = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private appSettings: AppSettings,
    private httpClient: HttpClient
  ) { }
  
  public async uploadFileLogo(file: any) {
    var formData: FormData = new FormData();
    formData.append('file', file, file.name)

    return await new Observable<[boolean, any]>((observer) => {
      this.httpClient.post<any>(environment.product_api + '/games/upload/image', formData, httpUploadOptions).subscribe(
        response => {
          let data = response;
          observer.next([true, data['data']]);
          observer.complete();
        },
        error => {
          observer.next([false, error.error.message]);
          observer.complete();
        });
    });
  }
  public async uploadFileBanner(file: any) {
    var formData: FormData = new FormData();
    formData.append('file', file, file.name)

    return await new Observable<[boolean, any]>((observer) => {
      this.httpClient.post<any>(environment.product_api + '/games/upload/image/banner', formData, httpUploadOptions).subscribe(
        response => {
          let data = response;
          observer.next([true, data['data']]);
          observer.complete();
        },
        error => {
          observer.next([false, error.error.message]);
          observer.complete();
        });
    });
  }
  public async uploadFileNftBanner(file: any) {
    var formData: FormData = new FormData();
    formData.append('file', file, file.name)

    return await new Observable<[boolean, any]>((observer) => {
      this.httpClient.post<any>(environment.product_api + '/games/upload/image/nft_banner', formData, httpUploadOptions).subscribe(
        response => {
          let data = response;
          observer.next([true, data['data']]);
          observer.complete();
        },
        error => {
          observer.next([false, error.error.message]);
          observer.complete();
        });
    });
  }
  
  public add_product(data: ProductModel): Observable<[boolean, any]> {
    let product_model: ProductModel = {
      id: data.id,
      game_name: data.game_name == '' ? '-' : data.game_name,
      game_banner: data.game_banner == '' ? '-' : data.game_banner,
      game_logo: data.game_logo == '' ? '-' : data.game_logo,
      game_link: data.game_link == '' ? '-' : data.game_link,
      game_price: data.game_price,
      ingame_images: data.ingame_images == '' ? '-' : data.ingame_images,
      trailer_link: data.trailer_link == '' ? '-' : data.trailer_link,
      upcoming: data.upcoming,
      status: data.status == '' ? 'DRAFT' : data.status,
      published: data.published,
      genre_id: data.genre_id,
      published_date: data.published_date,
      language: data.language = '' ? '-' : data.language,
      developer: data.developer = '' ? '-' : data.developer,
      onboarding: data.onboarding = '' ? '-' : data.onboarding,
      governance: data.governance = '' ? '-' : data.governance,
      release_date: data.release_date = ''? 'TBA': data.release_date,
      created_at: data.created_at,
      updated_at: data.updated_at,
      nft_api: data.nft_api,
      has_nft_drops: data.has_nft_drops,
      nft_banner: data.nft_banner == '' ? '-' : data.nft_banner,
      collection_id: data.collection_id
    };  
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient.post(environment.product_api + '/games', product_model, httpOptions).subscribe(
        (response: any) => {
          let data = response.data;
          observer.next([true, data]);
          observer.complete();
        },
        error => {
          observer.next([false, error.error.message]);
          observer.complete();
        });
    });
  }

  get_all_products(page_model: PageModel): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {

      let product_list: ProductModel[] = [];
      this.httpClient
        .get<any>(environment.product_api + '/games?page='+page_model.page+'&pageSize='+page_model.limit, httpOptions)
        .subscribe(
          (response) => {
            let result = response;
            let result_data = result['data'];
            if (result_data != null) {
              page_model = result_data
            }
            observer.next([true, page_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  get_all_products_dropdown(): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {

      let product_list: ProductDropdownModel[] = [];
      this.httpClient
        .get<any>(environment.product_api + '/games/dropdown', httpOptions)
        .subscribe(
          (response) => {
            let result = response;
            let result_data = result['data'];
            if (result_data != null) {
              product_list = result_data
            }
            observer.next([true, product_list]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  public get_product_by_id(id: string): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {
      let product_model: ProductModel;
      this.httpClient
        .get<any>(
          environment.product_api + '/games/' + id,
          httpOptions
        )
        .subscribe(
          (response) => {
            let result = response;
            if (response != null) {
              // Map genre_id array to extract just the genre IDs
              const genreIds = result['data'].game_genre.map((genreObj: any) => genreObj.genre_id);
              // const languages = result['data'].language.map((genreObj: any) => genreObj.id);
              product_model = {
                id: result['data'].id,
                game_banner: result['data'].game_banner,
                game_logo: result['data'].game_logo,
                game_name: result['data'].game_name,
                game_link: result['data'].game_link,
                ingame_images: result['data'].ingame_images != '' ? result['data'].ingame_images : '',
                trailer_link: result['data'].trailer_link,
                upcoming: result['data'].upcoming,
                genre_id: genreIds, // Assigning the array of genre IDs directly
                status: result['data'].status,
                game_price: result['data'].game_price,
                published: result['data'].published,
                published_date: result['data'].publish_date,
                language: result['data'].language==''|| result['data'].language=='-'? result['data'].language : JSON.parse(result['data'].language) ,
                developer: result['data'].developer,
                onboarding: result['data'].onboarding,
                governance: result['data'].governance,
                release_date: result['data'].release_date,
                created_at: result['data'].created_at,
                updated_at: result['data'].updated_at,
                nft_api: result['data'].nft_api,
                has_nft_drops: result['data'].has_nft_drops,
                nft_banner: result['data'].nft_banner,
                collection_id: result['data'].collection_id
              };
            }
            observer.next([true, product_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  
  
  update_product(
    id: number,
    data: ProductModel
  ): Observable<[boolean, ProductModel]> {
    let product_model: ProductModel = {
      id: data.id,
      game_name: data.game_name == '' ? '-' : data.game_name,
      game_logo: data.game_logo == '' ? '-' : data.game_logo,
      game_banner: data.game_banner == '' ? '-' : data.game_banner,
      game_link: data.game_link == '' ? '-' : data.game_link,
      ingame_images: data.ingame_images == '' ? '-' : data.ingame_images,
      trailer_link: data.trailer_link == '' ? '-' : data.trailer_link,
      upcoming: data.upcoming,
      status: data.status == '' ? 'DRAFT' : data.status,
      genre_id: data.genre_id,
      game_price: data.game_price,
      published: data.published,
      published_date: data.published_date,
      language:data.language =='' ? '-' : data.language,
      developer:data.developer ==''? '-': data.developer,
      onboarding:data.onboarding ==''? '-': data.onboarding,
      governance:data.governance ==''? '-':data.governance,
      release_date: data.release_date ==''? '-': data.release_date,
      created_at: data.created_at,
      updated_at: data.updated_at,
      nft_api: data.nft_api,
      nft_banner: data.nft_banner == '' ? '-' : data.nft_banner,
      has_nft_drops: data.has_nft_drops,
      collection_id: data.collection_id
    };
    return new Observable<[boolean, ProductModel]>((observer) => {
      this.httpClient
        .put<ProductModel>(
          environment.product_api + '/games/' + id,
          product_model,
          httpOptions
        )
        .subscribe(
          (response) => {
            let data = response;
            observer.next([true, data]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
    // Delete Storage Type Material By ID
    delete_product(id: number): Observable<[boolean, any]> {
      return new Observable<[boolean, any]>((observer) => {
        this.httpClient
          .delete<BlogModel>(
            environment.product_api + '/games/' + id,
            httpOptions
          )
          .subscribe(
            (response) => {
              observer.next([true, response]);
              observer.complete();
            },
            (error) => {
              observer.next([false, error.status]);
              observer.complete();
            }
          );
      });
    }
}
