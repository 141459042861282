import { Injectable, Input } from '@angular/core';
import { BlogModel } from "./../model/blog.model"
import { AppSettings } from 'src/app/app-settings';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PageModel } from "./../model/page.model"

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': ['application/json'],
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};
const httpUploadOptions = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(
    private appSettings: AppSettings,
    private httpClient: HttpClient
  ) { }

  get_all_blogs(page_model: PageModel,website:string): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {

      let blog_list: BlogModel[] = [];
      this.httpClient
        .get<any>(environment.blog_api + '/blogs?page='+page_model.page+'&pageSize='+page_model.limit+'&website='+website, httpOptions)
        .subscribe(
          (response) => {
            let result = response;
            let result_data = result['data'];
            if (result_data != null) {
              page_model = result_data
            }
            observer.next([true, page_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  public get_blog_by_id(id: number): Observable<[boolean, any]> {

    return new Observable<[boolean, any]>((observer) => {
      let blog_model: BlogModel;
      this.httpClient
        .get<any>(
          environment.blog_api + '/blogs/' + id,
          httpOptions
        )
        .subscribe(
          (response) => {
            let result = response;
            if (response != null) {
              blog_model = {
                id: result['data'].id,
                title: result['data'].title,
                featured_img: result['data'].featured_img,
                body: result['data'].body,
                author: result['data'].author,
                category: result['data'].category,
                status: result['data'].status,
                feature_blog: result['data'].feature_blog,
                blog_rank: result['data'].blog_rank,
                published: result['data'].published,
                published_blog: result['data'].published_blog,
                published_date: result['data'].published_date,
                created_at: result['data'].created_at,
                updated_at: result['data'].updated_at,
                website: result['data'].website
              };
            }
            console.log(result['data'].language);
            observer.next([true, blog_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }

  public add_blog(data: BlogModel): Observable<[boolean, any]> {
    let blog_model: BlogModel = {
      id: data.id,
      title: data.title == '' ? '-' : data.title,
      featured_img: data.featured_img == '' ? '-' : data.featured_img,
      body: data.body == '' ? '-' : data.body,
      author: data.author == '' ? '-' : data.author,
      category: data.category == '' ? '-' : data.category,
      status: data.status == '' ? 'DRAFT' : data.status,
      feature_blog: data.feature_blog,
      blog_rank: data.blog_rank,
      published: data.published,
      published_blog: data.published_blog,
      published_date: data.published_date,
      created_at: data.created_at,
      updated_at: data.updated_at,
      website: data.website
    };
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient.post(environment.blog_api + '/blogs', blog_model, httpOptions).subscribe(
        (response: any) => {
          let data = response.data;
          observer.next([true, data]);
          observer.complete();
        },
        error => {
          observer.next([false, error.error.message]);
          observer.complete();
        });
    });
  }

  update_blog(
    id: number,
    data: BlogModel
  ): Observable<[boolean, BlogModel]> {
    let blog_model: BlogModel = {
      id: data.id,
      title: data.title == '' ? '-' : data.title,
      featured_img: data.featured_img == '' ? '-' : data.featured_img,
      body: data.body == '' ? '-' : data.body,
      author: data.author == '' ? '-' : data.author,
      category: data.category == '' ? '-' : data.category,
      status: data.status == '' ? 'DRAFT' : data.status,
      feature_blog: data.feature_blog,
      blog_rank: data.blog_rank,
      published: data.published,
      published_blog: data.published_blog,
      published_date: data.published_date,
      created_at: data.created_at,
      updated_at: data.updated_at,
      website: data.website
    };
    return new Observable<[boolean, BlogModel]>((observer) => {
      this.httpClient
        .put<BlogModel>(
          environment.blog_api + '/blogs/' + id,
          blog_model,
          httpOptions
        )
        .subscribe(
          (response) => {
            let data = response;
            observer.next([true, data]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }

  // Delete Storage Type Material By ID
  delete_blog(id: number): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient
        .delete<BlogModel>(
          environment.blog_api + '/blogs/' + id,
          httpOptions
        )
        .subscribe(
          (response) => {
            observer.next([true, response]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }

  public async uploadFile(file: any) {
    var formData: FormData = new FormData();
    formData.append('file', file, file.name)

    return await new Observable<[boolean, any]>((observer) => {
      this.httpClient.post<any>(environment.blog_api + '/blogs/upload/image', formData, httpUploadOptions).subscribe(
        response => {
          let data = response;
          console.log(data);
          observer.next([true, data['data']]);
          observer.complete();
        },
        error => {
          observer.next([false, error.error.message]);
          observer.complete();
        });
    });
  }
}
