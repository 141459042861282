import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () => import("../../components/apps/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "blogs",
    loadChildren: () => import("../../components/apps/blogs/blogs.module").then((m) => m.BlogsModule),
  },
  {
    path: "labels",
    loadChildren: () => import("../../components/apps/labels/labels.module").then((m) => m.LabelsModule),
  },
  {
    path: "games",
    loadChildren: () => import("../../components/apps/products/product.module").then((m) => m.ProductsModule),
  },
  {
    path: "genre",
    loadChildren: () => import("../../components/apps/genre/genre.module").then((m) => m.GenreModule),
  },
  {
    path: "token",
    loadChildren: () => import("../../components/apps/token/token.module").then((m) => m.TokenModule),
  },
  {
    path: "system-requirements",
    loadChildren: () => import("../../components/apps/system-requirements/system-requirements.module").then((m) => m.SystemRequirementsModule),
  },
  {
    path: "news",
    loadChildren: () => import("../../components/apps/news/news.module").then((m) => m.NewsModule),
  },
  {
    path: "teams",
    loadChildren: () => import("../../components/apps/teams/teams.module").then((m) => m.TeamsModule),
  },
  {
    path: "xgame-nlp",
    loadChildren: () => import("../../components/apps/ai/xgame-nlp/xgame-nlp.module").then((m) => m.XGameNLPModule),
  },
  {
    path: "casino-nlp",
    loadChildren: () => import("../../components/apps/ai/casino-nlp/casino-nlp.module").then((m) => m.CasinoNLPModule),
  },
  {
    path: "image-generator",
    loadChildren: () => import("../../components/apps/ai/image-generator/image-generator.module").then((m) => m.ImageGeneratorModule),
  },
];
