import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { LabelTranslationModel } from './../model/label_translation.model';
import { PageModel } from "./../model/page.model"

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }),
};
@Injectable({
  providedIn: 'root'
})
export class LabelTranslationService {

  constructor(
    private httpClient: HttpClient
  ) { }

  public defaultAPIURLHost: string = environment.label_api;

  public add_label_translation(data: LabelTranslationModel): Observable<[boolean, any]> {
    let label_translation_model: LabelTranslationModel = {
      id: data.id,
      label_id: data.label_id,
      label: data.label == '' ? '-' : data.label,
      displayed_label: data.displayed_label == '' ? '-' : data.displayed_label,
      language: data.language == '' ? 'English' : data.language,
    };
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient.post(environment.label_api + '/labels_translation', label_translation_model, httpOptions).subscribe(
        (response: any) => {
          let data = response;
          observer.next([true, data]);
          observer.complete();
  
        },
        error => {
          observer.next([false, error.error.message]);
          observer.complete();
        });
    });
  }
  get_labels(page_model: PageModel,label_id: number): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {

      let label_list: LabelTranslationModel[] = [];

      this.httpClient
        .get<any>(this.defaultAPIURLHost + '/labels_translation?page='+page_model.page+'&pageSize='+page_model.limit+'&label_id='+label_id, httpOptions)
        .subscribe(
          (response) => {
            let result = response;
            let result_data = result['data'];
            if (result_data != null) {
              page_model = result_data
              // var data = results;
              // if (data.length > 0) {
              //   for (let i = 0; i <= data.length - 1; i++) {
              //     label_list.push({
              //       id: data[i].id,
              //       label: data[i].label,
              //       displayed_label: data[i].displayed_label,
              //     })
              //   }
              // }
            }
            observer.next([true, page_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  public get_label_translation_by_id(id: number): Observable<[boolean, any]> {

    return new Observable<[boolean, any]>((observer) => {
      let label_translation_model: LabelTranslationModel;
      this.httpClient
        .get<any>(
          environment.label_api + '/labels_translation/' + id,
          httpOptions
        )
        .subscribe(
          (response) => {
            console.log(response);
            let result = response;
            console.log(result);
            if (response != null) {
              label_translation_model = {
                id: result['data'].id,
                label_id: result['data'].label_id,
                label: result['data'].label,
                displayed_label: result['data'].displayed_label,
                language:result['data'].language,
              };
            }
            console.log(result['data'].language);
            observer.next([true, label_translation_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  update_label_translation(
    id: number,
    data: LabelTranslationModel
  ): Observable<[boolean, LabelTranslationModel]> {
    let blog_model: LabelTranslationModel = {
      id: data.id,
      label_id: data.label_id,
      label: data.label == '' ? '-' : data.label,
      displayed_label: data.displayed_label == '' ? '-' : data.displayed_label,
      language: data.language == '' ? 'English' : data.language,
    };
    return new Observable<[boolean, LabelTranslationModel]>((observer) => {
      this.httpClient
        .put<LabelTranslationModel>(
          environment.label_api + '/labels_translation/' + id,
          blog_model,
          httpOptions
        )
        .subscribe(
          (response) => {
            let data = response;
            observer.next([true, data]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }

  // Delete Storage Type Material By ID
  delete_label_translation(id: number): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient
        .delete<LabelTranslationModel>(
          environment.label_api + '/labels_translation/' + id,
          httpOptions
        )
        .subscribe(
          (response) => {
            observer.next([true, response]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
}
