import { Injectable } from '@angular/core';
import { TeamsModel } from "./../model/teams.model"
import { PageModel } from "./../model/page.model"
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': ['application/json'],
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};
const httpUploadOptions = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  page_model: PageModel = new PageModel();
  constructor(
    private httpClient: HttpClient
  ) { }

  public add_team(data: TeamsModel): Observable<[boolean, any]> {
    let team_model: TeamsModel = {
      id: data.id,
      team_name: data.team_name == '' ? '-' : data.team_name,
      status: data.status,
    };

    return new Observable<[boolean, any]>((observer) => {
      this.httpClient.post(environment.team_api + '/teams', team_model, httpOptions).subscribe(
        (response: any) => {
          let data = response.data;
          observer.next([true, data]);
          observer.complete();
        },
        error => {
          observer.next([false, error.error.message]);
          observer.complete();
        });
    });
  }
  get_all_teams(page_model: PageModel): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {

      let teams_list: TeamsModel[] = [];
      this.httpClient
        .get<any>(environment.team_api + '/teams?page='+page_model.page+'&pageSize='+page_model.limit, httpOptions)
        .subscribe(
          (response) => {
            let result = response;
            let result_data = result['data'];
            if (result_data != null) {
              page_model = result_data
              // var data = result['data'];
              // if (data.length > 0) {
              //   for (let i = 0; i <= data.length - 1; i++) {
              //     teams_list.push({
              //       id: data[i].id,
              //       team_name: data[i].team_name,
              //       status: data[i].status,
              //     })
              //   }
              // }
            }
            observer.next([true, page_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  public get_team_by_id(id: number): Observable<[boolean, any]> {

    return new Observable<[boolean, any]>((observer) => {
      let team_model: TeamsModel;
      this.httpClient
        .get<any>(
          environment.team_api + '/teams/' + id,
          httpOptions
        )
        .subscribe(
          (response) => {
            let result = response;
            if (response != null) {
              team_model = {
                id: result['data'].id,
                team_name: result['data'].team_name,
                status: result['data'].status,
                // created_at: result['data'].created_at,
                // updated_at: result['data'].updated_at,
              };
            }

            observer.next([true, team_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }

  update_team(
    id: number,
    data: TeamsModel
  ): Observable<[boolean, TeamsModel]> {
    let blog_model: TeamsModel = {
      id: data.id,
      team_name: data.team_name == '' ? '-' : data.team_name,
      status: data.status
      
    };
    return new Observable<[boolean, TeamsModel]>((observer) => {
      this.httpClient
        .put<TeamsModel>(
          environment.team_api + '/teams/' + id,
          blog_model,
          httpOptions
        )
        .subscribe(
          (response) => {
            let data = response;
            observer.next([true, data]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }

  // Delete Storage Type Material By ID
  delete_team(id: number): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient
        .delete<TeamsModel>(
          environment.team_api + '/teams/' + id,
          httpOptions
        )
        .subscribe(
          (response) => {
            observer.next([true, response]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
}
