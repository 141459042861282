import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppSettings } from './../../app-settings';
import { LoginModel } from '../../shared/model/login.model';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': ['application/json'],
    }),
  };

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private appSettings: AppSettings,
    private http: HttpClient
  ) { }

  public defaultAPIURLHost: string = this.appSettings.APIURLHostAuth;
  public options: any = {
    headers: new HttpHeaders({
      'Content-Type': ['application/x-www-form-urlencoded', 'application/json']
    })
  };

  userlogin(data: any): Observable<LoginModel | any> {
    return new Observable<LoginModel | any>((observer) => {
      this.http.post(this.defaultAPIURLHost + '/auth/login', data)
        .subscribe(
          (response) => {
            observer.next([true, response]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error]);
            observer.complete();
          }
        );
    });
  }
}
